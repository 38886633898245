
import './asst/css/bootstrap.min.css';
import './asst/css/tiny-slider.css';
import './asst/css/swiper-bundle.min.css';
import './asst/css/tobii.min.css';
import './asst/css/style.min.css';
import './asst/css/green.css';

import logo from './asst/images/logo.png';
import popup from './asst/images/popup.jpg';
import Home from './pages/Home';
import LANG from './asst/JSON/lang.json';
import { NavLink, Route, Routes } from 'react-router-dom';
import Fees from './pages/Fees';
import Academy from './pages/Academy';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ManPower from './pages/ManPower';
import { useState } from 'react';
import OwnerRegistration from './pages/OwnerRegistration';
import VendorRegistration from './pages/VendorRegistration';
import EmployeeRegistration from './pages/EmployeeRegistration';
import AcademyRegistration from './pages/AcademyRegistration';
import TermsOfServices from './pages/TermsOfServices';
import RefundPolicy from './pages/RefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {

  const [lang, setLang] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en')
  //Menu
  // Toggle menu
  const toggleMenu = () => {
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // Clickable Menu
  if (document.getElementById("navigation")) {
    var elements = document.getElementById("navigation").getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        // eslint-disable-next-line no-script-url
        if (elem.target.getAttribute("href") === "javascript:void(0)") {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
        }
      }
    }
  }

  // Menu sticky
  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (navbar != null) {
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  }

  window.addEventListener('scroll', (ev) => {
    ev.preventDefault();
    windowScroll();
  })

  // back-to-top
  var mybutton = document.getElementById("back-to-top");
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (mybutton != null) {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }


  const closeModal = () => {
    var myModalEl = document.getElementById('contactform');
    // eslint-disable-next-line no-undef
    var modal = bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
  }

  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{LANG[lang].title}</title>
        <meta name="description" content={LANG[lang].keywords} />
        <meta name="keywords" content={LANG[lang].keywords} />
        <meta name="author" content="the CoderSpace" />
        <meta name="website" content="https://theCoderspace.com/" />
        <meta name="email" content="support@theCoderspace.com" />
        <meta name="version" content="1.1.0" />
      </head>
      <body>

        <header id="topnav" className="defaultscroll sticky">
          <div className="container-fluid">

            <NavLink to="/" className="logo" >
              <span className="logo-light-mode">
                <img loading="lazy" src={logo} alt="" />
              </span>
              <img loading="lazy" src={logo} className="logo-dark-mode" alt="" />
            </NavLink>

            <div id="navigation">
              <ul className="navigation-menu nav-right" id="navmenu-nav">
                <li className="has-submenu">
                  <NavLink activeclassname='active' to="/">{LANG[lang].home}</NavLink>
                </li>
                <li className="has-submenu">
                  <NavLink activeclassname='active' to="/about-us">{LANG[lang].aboutus}</NavLink>
                </li>
                <li className="has-submenu">
                  <NavLink activeclassname="active" to="/man-power">{LANG[lang].manpower}</NavLink>
                </li>
                {/* <li className="has-submenu">
                  <NavLink activeclassname='active' to="/our-fees">{LANG[lang].ourfees}</NavLink>
                </li> */}
                <li className="has-submenu">
                  <NavLink activeclassname='active' to="our-academy">{LANG[lang].ouracademy}</NavLink>
                </li>
              </ul>
            </div>


            <ul className="buy-button list-inline mb-0 ">
              <li className={`list-inline-item ps-1 mb-0 ${lang === 'en' ? 'lang-active':''}`} onClick={() => { localStorage.setItem('lang', 'en'); setLang('en') }}>
                <a href="#">
                  <div className="btn btn-icon btn-pills btn-success d-sm-none d-inline-flex">EN</div>
                  <div className="rounded-0 d-none d-sm-block text-success">EN</div>
                </a>
              </li>
              <li className={`list-inline-item ps-1 mb-0 ${lang === 'tn' ? 'lang-active':''}`} onClick={() => { localStorage.setItem('lang', 'tn'); setLang('tn') }}>
                <a href="#">
                  <div className="btn btn-icon btn-pills btn-success d-sm-none d-inline-flex">தா</div>
                  <div className="rounded-0 d-none d-sm-block text-success">தா</div>
                </a>
              </li>
              <li className="list-inline-item ps-3 mb-0">
                <a href="" data-bs-toggle="modal" data-bs-target="#contactform">
                  <div className="btn btn-icon btn-pills btn-primary d-sm-none d-inline-flex"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-2 h-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                  </svg>
                  </div>
                  <span className="btn btn-primary rounded-2 d-none d-sm-block">{LANG[lang].login}</span>
                </a>
              </li>
            </ul>
            <div className="menu-extras">
              <div className="menu-item">
                <div className="navbar-toggle" id="isToggle" href="#" onClick={() => toggleMenu()}>
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Routes>
          <Route exact path="/" element={<Home lang={lang} />} />
          <Route exact path="/our-fees" element={<Fees lang={lang} />} />
          <Route exact path="/about-us" element={<AboutUs lang={lang} />} />
          <Route exact path="/our-academy" element={<Academy lang={lang} />} />
          <Route exact path="/man-power" element={<ManPower lang={lang} />} />
          <Route exact path="/owner-registration" element={<OwnerRegistration lang={lang} />} />
          <Route exact path="/vendor-registration" element={<VendorRegistration lang={lang} />} />
          <Route exact path="/employee-registration" element={<EmployeeRegistration lang={lang} />} />
          <Route exact path="/academy-registration" element={<AcademyRegistration lang={lang} />} />
          <Route exact path="/terms-of-services" element={<TermsOfServices lang={lang} />} />
          <Route exact path="/refund-policy" element={<RefundPolicy lang={lang} />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy lang={lang} />} />
        </Routes>

        <footer className="bg-footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-py-60">
                  <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                      <NavLink to={"/"} className="logo-footer">
                        <img loading="lazy" src={logo} alt="" />
                      </NavLink>
                      <p className="mt-4">{LANG[lang].footer}</p>

                    </div>

                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="footer-head">{LANG[lang].sitemap}</h5>
                      <ul className="list-unstyled footer-list mt-4">
                        <li><NavLink to="about-us" className="text-foot"><i
                          className="uil uil-angle-right-b me-1"></i> {LANG[lang].aboutus} </NavLink></li>                     
                        <li><NavLink to="man-power" className="text-foot"><i
                          className="uil uil-angle-right-b me-1"></i> {LANG[lang].manpower} </NavLink></li>
                        {/* <li><NavLink to="our-fees" className="text-foot"><i
                          className="uil uil-angle-right-b me-1"></i> {LANG[lang].ourfees} </NavLink></li> */}
                        <li><NavLink to="our-academy" className="text-foot"><i
                          className="uil uil-angle-right-b me-1"></i> {LANG[lang].ouracademy} </NavLink></li>

                      </ul>
                    </div>

                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="footer-head"> {LANG[lang].usefullink} </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        <li>
                          <NavLink to="/terms-of-services" className="text-foot">
                            <i className="uil uil-angle-right-b me-1"></i>
                            {LANG[lang].terms} </NavLink></li>
                        <li>
                          <NavLink to="/privacy-policy" className="text-foot">
                            <i className="uil uil-angle-right-b me-1"></i>
                            {LANG[lang].privacy} </NavLink></li>
                            <li>
                          <NavLink to="/refund-policy" className="text-foot">
                            <i className="uil uil-angle-right-b me-1"></i>
                            {LANG[lang].refund} </NavLink></li>
                      </ul>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="footer-head"> {LANG[lang].start} </h5>
                      <small className="d-block"> {LANG[lang].address} </small>
                      <small className="d-block"> {LANG[lang].phone} </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-py-30 footer-bar">
            <div className="container text-center">
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <div className="mb-0">©
                      <script>document.write(new Date().getFullYear())</script> இந்திய மாஸ்டர். Design with <i
                        className="mdi mdi-heart text-danger"></i> by <a href="https://theCoderspace.com/"
                          target="_blank" rel="noreferrer" className="text-reset">the CoderSpace</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="modal fade" id="contactform" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content rounded-0 shadow border-0 overflow-hidden">
              <div className="modal-body p-0">
                <div className="container-fluid px-0">
                  <div className="row align-items-center g-0">
                    <div className="col-lg-6 col-md-5">
                      <img loading="lazy" src={popup} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-7">
                      <div className="p-4 button-area">
                        <div className="row">
                          <div className="col-12 mt-2">
                            <div className="d-grid">
                              <NavLink to="/owner-registration"  id="ownerRegistration" onClick={() => closeModal()}
                                className="btn btn-primary rounded-0">{LANG[lang].OwnerRegistration}</NavLink>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="d-grid">
                              <NavLink  to="/employee-registration" id="employeeRegistration" onClick={() => closeModal()}
                                className="btn btn-primary rounded-0">{LANG[lang].EmployeeRegistration}</NavLink>
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="d-grid">
                              <NavLink to="/vendor-registration" id="vendorRegistration" onClick={() => closeModal()}
                                className="btn btn-primary rounded-0">{LANG[lang].VendorRegistration}</NavLink>
                            </div>
                          </div>
                          <div className="col-12 mt-2 mb-2">
                            <div className="d-grid">
                              <NavLink  to="/academy-registration" id="academyRegistration" onClick={() => closeModal()}
                                className="btn btn-primary rounded-0">{LANG[lang].AcademyRegistration}</NavLink>
                            </div>
                          </div>

                          {/* <div className="col-12 mt-4">
                            <div className="d-grid">
                              <NavLink  to="/owner-registration" id="ownerLogin" onClick={() => closeModal()}
                                className="btn btn-primary rounded-0">{LANG[lang].OwnerLogin}</NavLink>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <form className="p-4 hide" method="post" name="ownerRegistration">
                        <p id="error-msg" className="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label fw-normal">Your Name <span
                                className="text-danger">*</span></label>
                              <input name="name" id="name" type="text" className="form-control rounded-0"
                                placeholder="Name :" />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label fw-normal">Your Email <span
                                className="text-danger">*</span></label>
                              <input name="email" id="email" type="email"
                                className="form-control rounded-0" placeholder="Email :" />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="mb-3">
                              <label className="form-label fw-normal">Subject</label>
                              <input name="subject" id="subject" className="form-control rounded-0"
                                placeholder="subject :" />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="mb-3">
                              <label className="form-label fw-normal">Comments <span
                                className="text-danger">*</span></label>
                              <textarea name="comments" id="comments" rows="4"
                                className="form-control rounded-0" placeholder="Message :"></textarea>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NavLink href="#" onClick={() => topFunction()} id="back-to-top" className="back-to-top rounded-0 fs-5">
          <i data-feather="arrow-up"
            className="fea icon-sm icons align-middle">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          </i></NavLink>
      </body>
    </>
  );
}

export default App;
