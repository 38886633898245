import React from 'react'

function PrivacyPolicy() {
    return (
        <section className="d-table w-100 parllex section" data-jarallax='{"speed": 0.5}'>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
                <h2 className="text-3xl font-semibold text-gray-900 leading-10">Privacy Policy</h2>
                <p className="mt-4 text-gray-600 leading-6">
                    At Indian Master Job Portal, we value the privacy of our users and are committed to protecting their personal information. This Privacy Policy outlines how we collect, use, store, and disclose the information provided to us through our job portal platform. By using our services, you agree to the terms outlined in this policy.    </p>

                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    1. Information We Collect:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">
                    - Personal Information: When you create an account or apply for a job, we collect personal information such as your name, email address, contact number, and professional details. This information is necessary for job matching and communication purposes.
                    <br />
                    - Resume/CV: You have the option to upload your resume/CV to apply for jobs. This may contain additional personal information, work experience, education, and skills.
                    <br />
                    - Job Postings: If you are an employer, we collect information related to job postings, company details, and contact information.
                    <br />
                    - Log Data: Our servers automatically collect certain information when you access our platform, including your IP address, browser type, and usage statistics. This data is used for analytical purposes and to improve our services.
                </ul>

                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    2. Use of Information:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - Personalization: We use the collected information to personalize your job search experience, recommend relevant job opportunities, and provide tailored content.<br />
                    - Job Applications: When you apply for a job, we share your application and relevant information with the respective employer to facilitate the application process.<br />
                    - Communication: We may use your provided contact details to communicate with you regarding your account, job applications, notifications, and service-related updates.<br />
                    - Aggregated Data: We may aggregate and anonymize the collected information for statistical analysis, research, and reporting purposes.

                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    3. Data Retention:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - We retain your personal information as long as it is necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. If you wish to delete your account or have your data removed, please contact us using the information provided at the end of this policy.
                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    4. Information Security:
                </p>

                <ul className="mt-2 text-gray-600 leading-6">

                    - We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.<br />
                    - However, please note that no method of transmission or storage over the internet is 100% secure, and we cannot guarantee absolute security of your information.
                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    5. Third-Party Services:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - We may use third-party service providers to assist us in delivering our services, such as hosting, analytics, and email communication. These providers may have access to your personal information but are obligated to maintain its confidentiality and security.
                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    6. Cookies and Tracking Technologies:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - We use cookies and similar tracking technologies to enhance your browsing experience, analyze user behavior, and provide personalized content. You can manage your cookie preferences through your browser settings.
                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    7. Children's Privacy:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - Our services are not intended for individuals under the age of 16. We do not knowingly collect or store personal information from children. If we become aware of any data collected from children, we will take appropriate steps to delete it.
                </ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    8. Updates to this Privacy Policy:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">

                    - We may update this Privacy Policy from time to time. Any changes will be posted on our website, and your continued use of our services after such modifications constitutes your acceptance of the updated policy.</ul>
                <p className="mt-4 text-gray-600 leading-6 fw-bold">
                    9. Contact Us:
                </p>
                <ul className="mt-2 text-gray-600 leading-6">
                    - If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at  help@indianmaster.in.
                </ul>
            </div>
        </section>
    )
}

export default PrivacyPolicy