import React, { useState } from 'react'
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';
import PostApi from './../Services/PostApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string, number } from 'yup';
export default function OwnerRegistration(props) {
    const [cityList,] = useState([
        "Ariyalur",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Salem",
        "Sivaganga",
        "Thanjavur",
        "Theni",
        "Thoothukudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ])
    const validationSchema = object().shape({
        unavu_employer_cname: string().required('Company name is required'),
        unavu_employer_oname: string().required('Owner name is required'),
        unavu_employer_uname: string().required('Username is required'),
        unavu_employer_mobile: string().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits').required('Mobile number is required'),
        unavu_employer_password: string().min(6, 'Password must be at least 6 characters').required('Password is required'),
        unavu_employer_address: string().required('Address is required'),
        unavu_employer_city: string().required('City is required')
    });

    const initialValues = {
        unavu_employer_cname: '',
        unavu_employer_oname: '',
        unavu_employer_uname: '',
        unavu_employer_mobile: '',
        unavu_employer_password: '',
        unavu_employer_address: '',
        unavu_employer_city: ''
    };



    /*To save the form */
    const Save = (data) => {
        const intilizeValue = {
            query: 'add',
            values: data,
            'key': 'unavu_employer',
            condition: {}
        };
        PostApi("database.php", intilizeValue).then((e) => {
            if (e.responcePostData.data.status_code === 200) {
                document.getElementById("registration").reset();
                toast.success(LANG[props.lang].sucessMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(LANG[props.lang].errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    };



    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <ToastContainer />
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].OwnerRegistration}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-7">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={Save}
                            validationSchema={validationSchema}
                        >
                            <Form className="rounded shadow p-4" id="registration">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_cname">Company Name<span className="text-danger">*</span></label>
                                            <Field className="form-control" name="unavu_employer_cname" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_cname" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_oname">Owner Name<span className="text-danger">*</span></label>
                                            <Field className="form-control" name="unavu_employer_oname" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_oname" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_uname">Username<span className="text-danger">*</span></label>
                                            <Field className="form-control" name="unavu_employer_uname" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_uname" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_mobile">Mobile Number<span className="text-danger">*</span></label>
                                            <Field className="form-control" type="number" name="unavu_employer_mobile" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_mobile" />
                                        </div> </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_password">Password<span className="text-danger">*</span></label>
                                            <Field className="form-control" type="password" name="unavu_employer_password" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_password" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_city">City<span className="text-danger">*</span></label>
                                            <Field name="unavu_employer_city" as="select" className="form-control">
                                                <option value="">Select an option</option>
                                               {cityList.map(i =>(
                                                <option value={i}>{i}</option>
                                               ))}
                                            </Field>
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_city" />

                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="unavu_employer_address">Address<span className="text-danger">*</span></label>
                                            <Field className="form-control" as="textarea" name="unavu_employer_address" />
                                            <ErrorMessage component="p" className="text-danger" name="unavu_employer_address" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12 flex-end">
                                        <button type="submit" className="submitBnt btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div >
            </div >
        </section >
    </>
    )
}
