import React from 'react'
import one from '../asst/images/01.jpg';
import two from '../asst/images/02.jpg';
import cta from '../asst/images/cta.jpg';
import success from '../asst/images/square-success.png';
import LANG from '../asst/JSON/lang.json';

export default function Academy(props) {
    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].ouracademy}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="container mt-100 mb-60">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                    <div className="row g-3 align-items-center">
                        <div className="col-lg-7 col-6">
                            <img loading="lazy" src={one} className="img-fluid rounded-md" alt="work-image" />
                        </div>
                        <div className="col-lg-5 col-6">
                            <div className="row g-3">
                                <div className="col-lg-12 col-md-12">
                                    <img loading="lazy" src={two} className="img-fluid rounded-md" alt="work-image" />
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <img loading="lazy" src={success} className="avatar avatar-medium img-fluid rounded-md" alt="work-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 offset-lg-1 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title">
                        <h6 className="text-primary fw-normal mb-2">{LANG[props.lang].title}</h6>
                        <h4 className="title mb-4">{LANG[props.lang].ouracademy}</h4>
                        <p className="text-muted para-desc mb-0">{LANG[props.lang].ouracademy01}</p>
                        <div class="mt-4">
                            <div class="btn btn-pills btn-soft-primary m-2">{LANG[props.lang].online} </div>
                            <div class="btn btn-pills btn-soft-primary">{LANG[props.lang].offline}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="section pt-5">
            <div className="container mt-10" id="features">
                {/* <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title text-center">
                            <h4 className="title">{LANG[props.lang].online} / {LANG[props.lang].offline}</h4>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-lg-12 order-1 order-sm-2">
                        <div className="row">
                            {LANG[props.lang].cources.map((i) => (
                                <div className="col-sm-4 mt-4 pt-2" key={i.title}>
                                    <div className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                                        <div className="content">
                                            <p className="h5 text-dark title">{i.title}</p>
                                            {
                                                i.desc ?
                                                    <ul className="text sm">{i?.desc?.split(",").map(i => <li className="w-full">{i}</li>)}</ul>
                                                    : ""
                                            }
                                        </div>
                                        <div className="feature-ovelay-one"></div>
                                        <div className="feature-ovelay-two"></div>
                                        <div className="feature-ovelay-three"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}
