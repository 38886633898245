import React, { useState } from 'react'
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';
import PostApi from '../Services/PostApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string, number } from 'yup';
export default function AcademyRegistration(props) {
    const initialValues = {
        unavu_academy_name: '',
        unavu_academy_mobile: '',
        unavu_academy_email: '',
        unavu_academy_address: ''
    };

    /*To save the form */
    const Save = (data) => {
        const intilizeValue = {
            query: 'add',
            values: data,
            'key': 'unavu_academy',
            condition: {}
        };
        PostApi("database.php", intilizeValue).then((e) => {
            if (e.responcePostData.data.status_code === 200) {
                document.getElementById("registration").reset();
                toast.success(LANG[props.lang].sucessMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(LANG[props.lang].errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })

    };

    const validationSchema = object({
        unavu_academy_name: string().required('Name is required'),
        unavu_academy_mobile: number()
        .typeError('Phone number must be a number')
        .required('Phone number is required')
    });

    return (<>
        <section className="bg-half-170 d-table w-100 parllex" data-jarallax='{"speed": 0.5}' style={{ background: `url(${cta})` }}>
            <ToastContainer />
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{LANG[props.lang].AcademyRegistration}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-7">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={Save}
                            validationSchema={validationSchema}
                        >
                            <Form className="rounded shadow p-4" id="registration">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Name<span className="text-danger">*</span></label>
                                            <Field name="unavu_academy_name" type="text" className="form-control" />
                                            <ErrorMessage name="unavu_academy_name" component="p" className="text-danger" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Email</label>
                                            <Field name="unavu_academy_email" type="email" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Your Phone no<span className="text-danger">*</span></label>
                                            <Field name="unavu_academy_mobile" type="number" className="form-control" />
                                            <ErrorMessage name="unavu_academy_mobile" component="p" className="text-danger" />
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Address</label>
                                            <Field name="unavu_academy_address" as="textarea" rows="4" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 flex-end">
                                        <button type="submit" className="submitBnt btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}
