import React from 'react';
import marketing from '../asst/images/chef.png';
import cta from '../asst/images/cta.jpg';
import LANG from '../asst/JSON/lang.json';

function Home(props) {
  return (
    <>
    <section className="bg-soft-primary d-flex align-items-center mt-60" id="home">
          <div className="container">
            <div className="row align-items-center h-screen">
              <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 ">               
                    <img loading="lazy"src={marketing} className="img-fluid" alt="" />
                    {
                    /* <div className="play-icon">
                      <a href="" data-type="youtube" data-id="bYhYMzNaTbM" className="play-btn lightbox">
                        <i className="text-primary rounded-circle bg-white shadow-lg">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-2 h-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                          </svg>
                        </i>
                      </a>
                    </div> */
                    }                 
              </div>
              <div className="col-md-6">
                <div className="section-title text-center">
                  <h4 className="title mb-4">{LANG[props.lang].home01} <br /><b>{LANG[props.lang].title}</b></h4>

                  <p className="text-muted mb-0">
                  {LANG[props.lang].home02}
                    {/* <br /> <br />
                    {LANG[props.lang].home03} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-5">

          <div className="container mt-60" id="features">
            <div className="row align-items-end">
              <div className="col-lg-12 col-md-12">
                <div className="section-title text-center">
                  <h4 className="title">{LANG[props.lang].home04}</h4>
                </div>
              </div>


            </div>

            <div className="row">
              <div className="col-lg-3 order-2 order-sm-1 mt-4 pt-2">
                <div className="row sticky-bar">
                  <div className="col-md-12 col">
                    <div className="counter-box position-relative">
                      <h1 className="mb-0 fw-bold text-primary"><span className="counter-value"
                        data-target="10">3</span>+</h1>
                      <span className="counter-head fw-normal h6">{LANG[props.lang].home05}</span>
                    </div>
                  </div>

                  <div className="col-md-12 col mt-md-4">
                    <div className="counter-box position-relative">
                      <h1 className="mb-0 fw-bold text-primary"><span className="counter-value"
                        data-target="20">1</span>+</h1>
                      <span className="counter-head fw-normal h6">{LANG[props.lang].home06} <br /> {LANG[props.lang].home07}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 order-1 order-sm-2">
                <div className="row">
                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center rounded">
                        <i className="uil uil-presentation-line d-block rounded h4 mb-0 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                          </svg>

                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home08}</a>

                      </div>

                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-bill d-block h4 mb-0 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                          </svg>
                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home09}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-money-withdrawal d-block h4 mb-0 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                          </svg>


                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home10}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-presentation-line d-block h4 mb-0 p-3">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                          </svg>

                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home11}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-usd-circle d-block h4 mb-0 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
                          </svg>

                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home12}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>

                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-invoice d-block h4 mb-0 p-3">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                          </svg>

                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home13}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>
                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-invoice d-block h4 mb-0 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="d-block rounded h4 mb-0">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                          </svg>
                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home14}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>
                  <div className="col-sm-6 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-invoice d-block h4 mb-0 p-3">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                          </svg>



                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home15}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 pt-2">
                    <div
                      className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden">
                      <div className="icons text-center">
                        <i className="uil uil-invoice d-block h4 mb-0 p-3">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                          </svg>

                        </i>
                      </div>
                      <div className="content mt-4">
                        <a href="service-detail.html" className="h5 text-dark title">{LANG[props.lang].home16}</a>

                      </div>
                      <div className="feature-ovelay-one"></div>
                      <div className="feature-ovelay-two"></div>
                      <div className="feature-ovelay-three"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <div className="video-solution-cta position-relative z-index-1">
                  <div className="position-relative">
                    <div className="bg-half-260 d-table w-100 rounded parllex" data-jarallax='{"speed": 0.5}'
                      style={{ backgroundImage: `url(${cta})` }}></div>
                    <div className="play-icon">
                      <a href="" data-type="youtube" data-id="bYhYMzNaTbM" className="play-btn lightbox">
                        <i className="text-primary rounded-circle bg-white shadow-lg">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-2 h-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                  <div className="content mt-md-4 pt-md-2">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 text-center">
                        <div className="row align-items-center">
                          <div className="col-md-6 mt-4 pt-2">
                            <div className="section-title text-md-start">
                              <h6 className="text-white-50 fw-normal">{LANG[props.lang].title}</h6>
                              <h4 className="title text-white title-dark mb-0">{LANG[props.lang].home17} <br />
                              {LANG[props.lang].home18}</h4>
                            </div>
                          </div>

                          <div className="col-md-6 col-12 mt-4 pt-md-2">
                            <div className="section-title text-md-start">
                              <p className="text-white-50 para-desc"> {LANG[props.lang].home19}</p>
                              <p className="text-white-50 para-desc">{LANG[props.lang].home20}</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-posts-placeholder bg-primary"></div>
          </div>
        </section>




        {/* <section className="section bg-light">
          <div className="container mt-50 mt-60">
            <div className="row align-items-center">
              <div className="col-md-6 order-1 order-md-2">
                <div className="ms-lg-5">
                  <img loading="lazy"src={faq} className="img-fluid rounded shadow" alt="" />
                </div>
              </div>

              <div className="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h6 className="text-primary fw-normal">{LANG[props.lang].home21}</h6>
                  <h4 className="title mb-4">{LANG[props.lang].home22} <br /> {LANG[props.lang].home23}</h4>

                  <div className="accordion mt-4" id="buyingquestion">
                    <div className="accordion-item rounded shadow">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button fw-normal border-0 bg-light rounded shadow"
                          type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                          {LANG[props.lang].home24}
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse border-0 collapse show"
                        aria-labelledby="headingOne" data-bs-parent="#buyingquestion">
                        <div className="accordion-body text-muted bg-transparent">
                        {LANG[props.lang].home25}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item rounded shadow mt-2">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button fw-normal border-0 bg-light rounded shadow collapsed"
                          type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                          aria-expanded="false" aria-controls="collapseTwo">
                          {LANG[props.lang].home26}
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse border-0 collapse"
                        aria-labelledby="headingTwo" data-bs-parent="#buyingquestion">
                        <div className="accordion-body text-muted bg-transparent">
                          {LANG[props.lang].home25}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item rounded shadow mt-2">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button fw-normal border-0 bg-light rounded shadow collapsed"
                          type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                          aria-expanded="false" aria-controls="collapseThree">
                          {LANG[props.lang].home27}
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse border-0 collapse"
                        aria-labelledby="headingThree" data-bs-parent="#buyingquestion">
                        <div className="accordion-body text-muted bg-transparent">
                          {LANG[props.lang].home25}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item rounded shadow mt-2">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button fw-normal border-0 bg-light rounded shadow collapsed"
                          type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                          aria-expanded="false" aria-controls="collapseFour">
                          {LANG[props.lang].home28}
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse border-0 collapse"
                        aria-labelledby="headingFour" data-bs-parent="#buyingquestion">
                        <div className="accordion-body text-muted bg-transparent">
                          {LANG[props.lang].home25}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section pt-5 pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={amazon} className="avatar avatar-ex-sm" alt="" />
              </div>

              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={google} className="avatar avatar-ex-sm" alt="" />
              </div>

              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={lenovo} className="avatar avatar-ex-sm" alt="" />
              </div>

              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={paypal} className="avatar avatar-ex-sm" alt="" />
              </div>

              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={shopify} className="avatar avatar-ex-sm" alt="" />
              </div>

              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <img loading="lazy"src={spotify} className="avatar avatar-ex-sm" alt="" />
              </div>
            </div>
          </div>
        </section> */}
    
    </>
  )
}

export default Home;