import React, { Component } from 'react'

export class RefundPolicy extends Component {
    render() {
        return (
            <section className="d-table w-100 parllex section" data-jarallax='{"speed": 0.5}'>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
                    <h2 className="text-3xl font-semibold text-gray-900 leading-10">Refund Policy</h2>
                    <p className="mt-4 text-gray-600 leading-6">
                        We want you to be satisfied with your purchase, and we offer a full refund within 30 days of purchase if you are not happy with our service.
                    </p>
                    <p className="mt-4 text-gray-600 leading-6">
                        To request a refund, please contact our support team at <a href="mailto:help@indianmaster.in">help@indianmaster.in</a> or by phone at <a href="tel:08248473365">08248473365</a>.
                    </p>
                    <p className="mt-4 text-gray-600 leading-6">
                        To be eligible for a refund, you must:
                    </p>
                    <ul className="mt-2 text-gray-600 leading-6">
                        <li>Have purchased your plan within the past 30 days.</li>
                        <li>Have at least one active job post for 3 days post your purchase.</li>
                        <li>Have a verified profile that adheres to the Indian Master policies.</li>
                    </ul>
                    <p className="mt-4 text-gray-600 leading-6">
                        Once your refund request has been approved, we will process the refund and credit your original payment method within 4-5 business days.
                    </p>
                    <p className="mt-4 text-gray-600 leading-6">
                        In some cases, we may not be able to issue a refund. For example, if you have already filled a job post or if you have exceeded the number of active job posts allowed in your plan.
                    </p>
                    <p className="mt-4 text-gray-600 leading-6">
                        Indian Master is not liable for any replacements, recovery, or liability in the following cases:
                    </p>
                    <ul className="mt-2 text-gray-600 leading-6">
                        <li>Employee leaving</li>
                        <li>Employee misconduct</li>
                        <li>Employee background verification</li>
                        <li>Employee criminal activities</li>
                    </ul>
                    <p className="mt-4 text-gray-600 leading-6">
                        Indian Master has a blacklisting policy for companies that fall under the following categories:
                    </p>
                    <ul className="mt-2 text-gray-600 leading-6">
                        <li>Consultancies</li>
                        <li>Vulgar Jobs</li>
                        <li>MLM Jobs</li>
                        <li>Click Bait Jobs</li>
                    </ul>
                    <p className="mt-4 text-gray-600 leading-6">
                        By purchasing a plan from Indian Master, you agree to the refund policy and the blacklisting policy.
                    </p>
                </div>
            </section>

        )
    }
}

export default RefundPolicy